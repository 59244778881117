import React, { useState } from "react";
import ReactDOM from "react-dom";
import { MdClose, MdMenu } from "react-icons/md";

import { Link } from 'react-router-dom';

import { useTransition, animated, config } from "react-spring";
import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import Triangle from "../../blocks/triangle/Triangle";
import "./assets/css/index.css";
import Logo from "../../assets/images/alba-logo.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

import { withTranslation, Trans } from 'react-i18next';


const styleToggleButton = {
  fontSize: "3rem",
  color: "rgb(36,36,36)",
  padding: 0,
  border: "none",
  background: "none"
};

const FullscreenMenu = (props) => {


  const [isOpen, setIsOpen] = useState(false);


  const openButton = useTransition(isOpen, {
    from: {
      opacity: 0,
      color: "#fff",
      position: "relatative",
      transform: "scale(0)"
    },
    enter: {
      opacity: 1,
      transform: "scale(1)"
    },
    leave: { opacity: 0, transform: "scale(0)" },
    config: config.default
  });
  const fullscreenMenu = useTransition(isOpen, {
    from: {
      opacity: 0,
      transform: "scale(0.80)",
      position: "relative",
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
      backgroundImage: "linear-gradient(120deg, #000 0%, #000 100%)",
      position: "absolute",
      maxHeight: "100vh",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      overflow: "hidden"
    },
    leave: { opacity: 0, height: "0px", transform: "scale(0.80)", position: "relative" },
    config: config.stiff
  });


  const toggleMenu = () => {
    document.body.classList.toggle('menu-open');
      setIsOpen(!isOpen);
    };


    return (
    <div
      className="fullscreen-menu"
      style={{
        position: isOpen ? "fixed" : "relative",
        left: "0px",
        top: "0px",
        right: "0px",
        bottom: "0px",
      }}
    >


    <div style={{ position: "relative", zIndex: 20 }}>
        {openButton((style, item, key, props) => (
          !item ? (
            <animated.div key={key} style={style}>
              <button className="open-btn" style={styleToggleButton} onClick={toggleMenu}>
                <FontAwesomeIcon icon={AllLightIcons.faAlignLeft} /> <Trans>Menu</Trans>
              </button>
            </animated.div>
          ) : (
            <animated.div key={key} style={style}>
              <button className="close-btn" style={styleToggleButton} onClick={toggleMenu}>
                <MdClose />
              </button>
            </animated.div>
          )
        ))}
      </div>




      <div>
        {fullscreenMenu((style, item, key) => (
            item && (
              <animated.div key={key} style={style}>

                <div className="logo-bar">
                  <img src={Logo} />
                </div>

                <div className="headline-bar">
                  {props.t('Menu')}
                </div>

                <div className="menu-bar">
                  <ul
                    style={{
                      height: "100%",
                      textAlign: 'center',
                      fontSize: "2vw",
                      padding: 0,
                      margin: 0,
                      listStyle: "none",
                      overflow: "hidden",
                    }}
                  >
                    <li>
                      <Link to="/home" onClick={() => { toggleMenu() }}>
                        {props.t('Home')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/lineup" onClick={() => { toggleMenu() }}>
                        {props.t('Line-Up')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/tickets" onClick={() => { toggleMenu() }}>
                        {props.t('Tickets')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/festival" onClick={() => { toggleMenu() }}>
                        {props.t('Festival')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/infos" onClick={() => { toggleMenu() }}>
                        {props.t('Informationen')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/history" onClick={() => { toggleMenu() }}>
                        {props.t('History')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={() => { toggleMenu() }}>
                        {props.t('Kontakt')}
                      </Link>
                    </li>
                  </ul>
                </div>


                <hr />


                <div className="social-bar">
                  <h2>{props.t('Follow Us')}</h2>
                  <ul
                    style={{
                      height: "100%",
                      textAlign: 'center',
                      fontSize: "1vw",
                      padding: 0,
                      margin: 0,
                      listStyle: "none",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <li>
                      <a href="https://www.facebook.com/albafestival/" target="_blank">
                        <FontAwesomeIcon icon={AllBrandIcons.faFacebook} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/albafestival/?hl=de" target="_blank">
                        <FontAwesomeIcon icon={AllBrandIcons.faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/albafestival?lang=de" target="_blank">
                        <FontAwesomeIcon icon={AllBrandIcons.faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCg1zQ3NNVHD_86mwN47hpdQ" target="_blank">
                        <FontAwesomeIcon icon={AllBrandIcons.faYoutube} />
                      </a>
                    </li>

                    <li>
                      <a href="https://vm.tiktok.com/ZMdQvHf5R/" target="_blank">
                        <FontAwesomeIcon icon={AllBrandIcons.faTiktok} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.snapchat.com/add/albafestival" target="_blank">
                        <FontAwesomeIcon icon={AllBrandIcons.faSnapchat} />
                      </a>
                    </li>
                  </ul>
                </div>


                <div className="terms-bar">
                  <ul
                    style={{
                      height: "100%",
                      textAlign: 'center',
                      fontSize: "2vw",
                      padding: 0,
                      margin: 0,
                      listStyle: "none",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                  <li>
                    <Link to="/privacy" onClick={() => { toggleMenu() }}>
                      {props.t('Datenschutz')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/imprint" onClick={() => { toggleMenu() }}>
                      {props.t('Impressum')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" onClick={() => { toggleMenu() }}>
                      {props.t('AGB')}
                    </Link>
                  </li>

                  </ul>
                </div>




                <div className="animatesTriangleLeft">
                  <Triangle />
                </div>

                <div className="animatesTriangleRight">
                  <Triangle />
                </div>


              </animated.div>
            )
        ))}
      </div>



  </div>
  )




}


export default withTranslation()(FullscreenMenu);
