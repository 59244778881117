import React from 'react';
import FullscreenMenu from '../../modules/menu/FullscreenMenu';
import { Link } from "react-router-dom";

import Logo from "../../assets/images/alba-logo.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

import i18n from '../../i18n';
import { withTranslation, Trans } from 'react-i18next';
import English from "../../../node_modules/language-icons/icons/en.svg"
import German from "../../../node_modules/language-icons/icons/de.svg"
import Italian from "../../../node_modules/language-icons/icons/it.svg"
import French from "../../../node_modules/language-icons/icons/fr.svg"
import Albania from "../../../node_modules/language-icons/icons/sq.svg"
import ReactCountryFlag from "react-country-flag"



class Header extends React.Component {

  constructor(props) {
    super(props);



    this.state = {
      currentLang: (localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'de')
    }
  }


  componentDidMount() {

    if(localStorage.getItem('i18nextLng') == 'null' || localStorage.getItem('i18nextLng') == '') {
      this.setState({
        currentLang: 'de'
      },() => {
        localStorage.setItem('i18nextLng', 'de');
        i18n.changeLanguage('de');
      });
    } else {
      this.changeLanguage(this.state.currentLang);
    }
  }


  changeLanguage = (lng) => {
    this.setState({
      currentLang:lng
    },() => {
      localStorage.setItem('i18nextLng', lng);
      i18n.changeLanguage(lng);
    });
  }

  render() {


    return (
      <>
      <header id="top-bar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4">


              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className="before">
                    <FontAwesomeIcon icon={AllLightIcons.faCommentAlt} />
                  </span>
                  <span className="country-flag">
                    {this.state.currentLang}
                  </span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {this.state.currentLang != 'en' &&
                    <li>
                      <a onClick={() => { this.changeLanguage('en') }} class="dropdown-item" href="#">
                        EN
                      </a>
                    </li>
                  }

                  {this.state.currentLang != 'de' &&
                    <li>
                      <a onClick={() => { this.changeLanguage('de') }} class="dropdown-item" href="#">
                        DE
                      </a>
                    </li>
                  }

                  {this.state.currentLang != 'it' &&
                    <li>
                      <a onClick={() => { this.changeLanguage('it') }} class="dropdown-item" href="#">
                        IT
                      </a>
                    </li>
                  }

                  {this.state.currentLang != 'fr' &&
                    <li>
                      <a onClick={() => { this.changeLanguage('fr') }} class="dropdown-item" href="#">
                        FR
                      </a>
                    </li>
                  }

                  {this.state.currentLang != 'sq' &&
                    <li>
                      <a onClick={() => { this.changeLanguage('sq') }} class="dropdown-item" href="#">
                        SQ
                      </a>
                    </li>
                  }

                </ul>
              </div>

              {/* show btn Modal */}
            {/*
              <a href="https://www.starticket.ch/de/tickets/alba-festival-2021-20210904-1400-20210905-2330-hardturm-areal-zurich" target="_blank" className="btn btn-primary btn-ticket">
                <FontAwesomeIcon icon={AllLightIcons.faTicket} />

                  {this.props.t('Jetzt Ticket sichern')}

              </a>

              */}






            </div>
            <div className="col-sm-4">
              <Link to="/"><img src={Logo} className="logo" /></Link>
            </div>
            <div className="col-sm-4">



              <FullscreenMenu />
            </div>
          </div>
        </div>
      </header>




      </>
    );
  }
}


export default withTranslation()(Header);
