import React from 'react';
import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import { Helmet } from 'react-helmet'
import axios from "axios";
import TriangleAnimated from "../blocks/triangle/TriangleAnimated";
import PagePreloader from "../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';
import config from '../config';


class Terms extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      session: false,
      images: null,
      content: null,
      currentLang: this.props.i18n.language
    };

  }

  componentDidMount() {

    var component = this;


    if(!this.state.session) {
      const result =  axios(
        config.backendBase + 'session/token',
      );

      component.setState({
        session: result.data,
      });
    }

    this.loadPage();

  }


  componentDidUpdate(prevProps, prevState) {
    var component = this;

    //alert(prevProps.i18n.language);

    if(prevState.currentLang != this.props.i18n.language) {
      component.setState({
        currentLang: this.props.i18n.language
      });
      this.loadPage();
    }
  }

  loadPage () {

    var component = this;


      var lang = '';

      if(this.state.currentLang != 'de') {
        lang = this.state.currentLang + '/';
      }

      axios({
        method: 'GET',
        url: config.backendBase+lang+'jsonapi/node/page/94d01918-c2f6-4b0f-a344-aabb1e311063',
        headers: {
          'Content-Type': 'json',
          'X-CSRF-Token': this.state.session,
        }
      })
        .then(res => {

          component.setState({
            content: res.data.data,
            images: res.data.included,
            loading: false,
          });

        })
        .catch(err => {
          console.log(err);
          component.setState({
            loading: false,
          });
        });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="Terms" className="container page">

          <div className="animatesTriangleRight">
            <TriangleAnimated />
          </div>

          <div className="animatesTriangleLeft">
            <TriangleAnimated />
          </div>

          {this.state.loading &&
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          }

          {!this.state.loading &&
            <>
            <h1>{this.props.t('ALLGEMEINE GESCHÄFTSBEDINGUNGEN')}</h1>
            <div dangerouslySetInnerHTML={{ __html: this.state.content.attributes.body.value }} />
            </>
          }

        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Terms);
