import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Link } from "react-router-dom";

import FooterMap from './FooterMap'
import FooterPartner from './FooterPartner'
import FooterNewsletter from './FooterNewsletter'

import TimeEventLogo from '../../assets/images/time-event-white.svg'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

import Triangle from "../../blocks/triangle/Triangle";
import TrianglePath from "../../blocks/triangle/TrianglePath";
import TrianglePathAnimated from "../../blocks/triangle/TrianglePathAnimated";

import SpotifyWebPlayer from 'react-spotify-web-playback';

import { SpotifyAuth, Scopes } from 'react-spotify-auth'
import 'react-spotify-auth/dist/index.css'
import { SpotifyApiContext } from 'react-spotify-api'
import Cookies from 'js-cookie'
import { withTranslation, Trans } from 'react-i18next';



class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      token: Cookies.get('spotifyAuthToken')
    }
  }

  componentDidMount() {
    var component = this;

    var tokenInterval = setInterval(function(){
      var token = Cookies.get('spotifyAuthToken');

      if(token) {
        component.setState({ token: token });
        clearInterval(tokenInterval);
      }

    }, 3000);
  }

  render() {


    return (
      <>


      <div class="footer">
        <footer id="footer-map-bar">
            <FooterMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC1IYfbr2tiiSjEuUuu-EU138-ZYNzIozg"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />


            <address>HARDTURM STADION ZÜRICH  |  Hardturmstrasse 404, Zürich</address>

        </footer>

        <footer id="footer-partner-bar" className="container">
          <h5>{this.props.t('Sponsoren & Partner')}</h5>
          <FooterPartner />
        </footer>




        <footer id="footer-info-bar">
          <h6>{this.props.t('Follow Us')}</h6>
            <ul
              style={{
                height: "100%",
                textAlign: 'center',
                fontSize: "1vw",
                padding: 0,
                paddingBottom: 20,
                margin: 0,
                listStyle: "none",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center"
              }}
              className="social-bar"
            >
            <li>
              <a href="https://www.facebook.com/albafestival/" target="_blank">
                <FontAwesomeIcon icon={AllBrandIcons.faFacebook} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/albafestival/?hl=de" target="_blank">
                <FontAwesomeIcon icon={AllBrandIcons.faInstagram} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/albafestival?lang=de" target="_blank">
                <FontAwesomeIcon icon={AllBrandIcons.faTwitter} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCg1zQ3NNVHD_86mwN47hpdQ" target="_blank">
                <FontAwesomeIcon icon={AllBrandIcons.faYoutube} />
              </a>
            </li>

            <li>
              <a href="https://vm.tiktok.com/ZMdQvHf5R/" target="_blank">
                <FontAwesomeIcon icon={AllBrandIcons.faTiktok} />
              </a>
            </li>
            <li>
              <a href="https://www.snapchat.com/add/albafestival" target="_blank">
                <FontAwesomeIcon icon={AllBrandIcons.faSnapchat} />
              </a>
            </li>
          </ul>

          <hr />

          <FooterNewsletter />

          <hr />


            <footer id="footer-time-event">
              <img src={TimeEventLogo} className="img-fluid" />
            </footer>

          <div className="term-bar">
            <ul
              style={{
                height: "100%",
                textAlign: 'center',
                fontSize: "2vw",
                padding: 0,
                margin: 0,
                listStyle: "none",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <li>
                <Link to="/privacy">{this.props.t('Datenschutz')}</Link>
              </li>
              <li>
                <Link to="/imprint">{this.props.t('Impressum')}</Link>
              </li>
              <li>
                <Link to="/terms">{this.props.t('AGB')}</Link>
              </li>
            </ul>
          </div>



          <div className="copyright">Copyright 2021 © alba Festival</div>

            <div className="animatesTriangleLeft">
              <TrianglePath />
            </div>

            <div className="animatesTriangleRight">
              <TrianglePathAnimated />
            </div>
        </footer>
        {/*
          <footer id="footer-radio">


            {this.state.token ? (
            <SpotifyApiContext.Provider value={this.state.token}>
              <SpotifyWebPlayer
                token={this.state.token}
                uris={['spotify:playlist:4eB9Tl3dNSCVelOH0zUzLq']}

              />
            </SpotifyApiContext.Provider>
          ) : (
            // Display the login page
            <SpotifyAuth
              title={'Listen to Alba Radio with Spotify'}
              redirectUri='https://preview.alba-festival.ch/'
              clientID='e3ddbd7661ad415ea6294062164ba718'
              scopes={['streaming', 'user-read-email', 'user-read-private', 'user-read-recently-played', 'user-modify-playback-state', 'user-read-playback-state']} // either style will work
            />
          )}



          </footer>
          */}
      </div>


      </>
    );
  }
}


export default withTranslation()(Footer);
