import React,{ lazy, Suspense } from 'react';
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom';
import { history } from './helpers/history';
import './index.css';
import './assets/css/app.css';
import './assets/css/responsive.css';
import './assets/css/animate.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
// import i18n (needs to be bundled ;))
import './i18n';

ReactDOM.render(
    <Router history={history}>
      <Suspense fallback={<div></div>}>
        <App />
      </Suspense>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
