import { useState, useEffect, useRef } from 'react'
import Ticker from 'react-ticker'
import { Modal } from 'bootstrap'
import HomeArtist from '../home/HomeArtist'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

function ArtistHeadline(props) {


  const [speed, setSpeed] = useState(5);
  const [loading, setLoading] = useState(true);




  function onMouseEnterHandler(artist) {
    setSpeed(1);
  }

  function onMouseLeaveHandler(artist) {
    setSpeed(5);
  }





  return (
    <>



      <>
      <Ticker direction="toLeft" speed={speed}>
        {({ index }) => (
          <>
            <HomeArtist mouseEnter={onMouseEnterHandler} mouseLeave={onMouseLeaveHandler} artist={props.artist} />
          </>
        )}
      </Ticker>
      <Ticker direction="toRight" offset="100%" speed={speed}>
        {({ index }) => (
          <>
            <HomeArtist mouseEnter={onMouseEnterHandler} mouseLeave={onMouseLeaveHandler} artist={props.artist} />
          </>
        )}
      </Ticker>
    <Ticker speed={speed}>
        {({ index }) => (
          <>
            <HomeArtist mouseEnter={onMouseEnterHandler} mouseLeave={onMouseLeaveHandler} artist={props.artist} />
          </>
        )}
      </Ticker>
      <Ticker direction="toRight" offset="100%" speed={speed}>
        {({ index }) => (
          <>
            <HomeArtist mouseEnter={onMouseEnterHandler} mouseLeave={onMouseLeaveHandler} artist={props.artist} />
          </>
        )}
      </Ticker>


      </>
    </>
  );
}

export default ArtistHeadline;
