import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import './assets/css/index.css';
import Ticker from 'react-ticker'
import FestivalHeadline from "./FestivalHeadline";
import PagePreloader from "../../helpers/PagePreloader";
import Ticket from "../../blocks/ticket/Ticket";
import FestivalGrid from "./FestivalGrid";
import { withTranslation, Trans } from 'react-i18next';




class Festival extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="festival">

          <div className="animatesTriangleRight">
            <TriangleAnimated />
          </div>

          <div className="animatesTriangleLeft">
            <TriangleAnimated />
          </div>


          <h1>{this.props.t('Festival')}</h1>

          <div className="container">
            <div className="body">
              {this.props.t('Unser Wunsch ist es, Menschen aus ganz Europa zu vereinigen und gemeinsam zwei unvergessliche Tage zu erleben. Mit dem alba Openair-Festival möchten wir etwas albanische Kultur nach Zürich bringen.')}
              <br />
              {this.props.t('Das Festival heisst alle Musik- und Kulturliebhaber herzlich willkommen.')}
            </div>
          </div>

          <div className="container festival-plan-bar">
            <button style={{background: 'none', border: 'none'}} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#planModal">
              <img src="https://www.alba-festival.ch/plan.jpg" />
            </button>
            <hr />
          </div>

          <div className="container-fluid container-list">
            <FestivalGrid />
          </div>

          <Ticket />


        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Festival);
