import React, {useState} from 'react';
import TicketLogo from "../../assets/images/SEE-TICKETS_Logo_Black.jpeg";
import Iframe from 'react-iframe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'
import { withTranslation, Trans } from 'react-i18next';


function Ticket(props) {

  return (
    <>
    <div className="ticket-bar">
      <div className="ticket-logo-bar">
        <img src={TicketLogo} className="img-fluid" />
      </div>
      <h3>{props.t('Jetzt Ticket sichern')}</h3>
      <a href="https://www.starticket.ch/de/tickets/alba-festival-2021-20210904-1400-20210905-2330-hardturm-areal-zurich" target="_blank" className="btn btn-primary" >
        <FontAwesomeIcon icon={AllLightIcons.faTicket} /> {props.t('Zum Ticketshop')}
      </a>
    </div>





    </>
  );
}

export default withTranslation()(Ticket);
