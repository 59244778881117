import React from 'react';
import { Helmet } from 'react-helmet'


import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import IntroBg from "../../assets/images/homeIntroBg.jpeg";
import './assets/css/index.css';




import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import Ticket from "../../blocks/ticket/Ticket";

import HeaderVideo from "../../assets/videos/headerVideo.mp4";


import HomeCountdown from "./HomeCountdown";
import HomeCovid from "./HomeCovid";
import HomeIntroHeadline from "./HomeIntroHeadline";
import HomeScrollButton from "./HomeScrollButton";
import HomeLineup from "./HomeLineup";

import ScrollAnimation from 'react-animate-on-scroll';
import PagePreloader from "../../helpers/PagePreloader";

import { withTranslation, Trans } from 'react-i18next';




class Home extends React.Component {

  constructor(props) {
    super(props);

    //console.log(props);

    this.state = {
      loading: true,
    };

    this.homeWelcomeRef = React.createRef()

  }


  componentDidMount() {

    this.setState({ loading: false });

  }




  render() {


    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="home">

          <div className="intro-bar">

            <div className="video-bar">
              <video playsInline autoPlay muted loop preload="metadata" className="H_i_h" data-automation="VideoPlayer_video_video">
                <source src={HeaderVideo} type="video/webm" />
                <source src={HeaderVideo} type="video/mp4" />
                <source src={HeaderVideo} type="video/ogg" />
              </video>
            </div>

            <div className="stroke-headline-bar-01">
              <HomeIntroHeadline text="We are one." delay="1500" />
            </div>

            <div className="stroke-headline-bar-02">
              <HomeIntroHeadline text="We are alba." delay="2500" />
            </div>

            <HomeCovid />

            <HomeCountdown />

            <div className="arrow-bar">
              <HomeScrollButton scrollTo={this.homeWelcomeRef} />
            </div>


            <div className="bg-bar">
              <img src={IntroBg} />
            </div>


            <div className="triangle-bar">
              <div className="animatesTriangleLeft">
                <TriangleAnimated />
              </div>

              <div className="animatesTriangleRight">
                <TriangleAnimated />
              </div>
            </div>

          </div>



          <div className="home-welcome-bar" ref={this.homeWelcomeRef}>


              <div className="container-fluid">
                <h1>{this.props.t('Das grösste albanische Festival')}</h1>
                <h2>{this.props.t('in Zürich, Schweiz')}</h2>
                <div className="body">
                  {this.props.t('Wir laden alle Liebhaberinnen und Liebhaber der albanischen Musikszene und Kultur aus ganz Europa ein, Teil dieses einzigartigen Events zu werden. Das alba Festival (Openair) ist ein fantastisches, unvergessliches Festival-Wochenende im Herzen von Zürich. Es erwarten dich albanische Künstlerinnen und Künstler aus der ganzen Welt. Folge uns in der Zwischenzeit auf unseren sozialen Kanälen mit Beiträgen zum vergangenen und Neuigkeiten zu kommenden alba Festivals.')}
                </div>


                <div className="icon-bar">
                  <FontAwesomeIcon icon={AllLightIcons.faChevronDoubleDown} />
                </div>

                <h3>{this.props.t('you are welcome here')}</h3>
                <h4>{this.props.t('2 Days of Albanian Music, Fun & Culture')}</h4>
              </div>

              <div className="animatesTriangleRight">
                <TriangleAnimated />
              </div>






          </div>



          <Ticket />

          <div className="home-lineup-bar">
            <h3>{this.props.t('Line-up 2021')}</h3>
            <HomeLineup />
          </div>




        </section>

        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Home);
