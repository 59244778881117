import { useState, useEffect, useRef } from 'react'
import { Modal } from 'bootstrap'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import { withTranslation, Trans } from 'react-i18next';
import config from '../../config';

function InfoAccordions(props) {


  const [loading, setLoading] = useState(true);
  const [infos, setInfos] = useState(null);
  const [icons, setIcons] = useState(null);
  const [session, setSession] = useState(null);
  const [currentLang, setCurrentLang] = useState(props.i18n.language);


  useEffect(() => {
   setCurrentLang(props.i18n.language);


   if(!session) {
     const result = axios(
       config.backendBase + 'session/token',
     );

     setSession(result.data);


     var lang = '';

     if(props.i18n.language != 'de') {
       lang = props.i18n.language + '/';
     }

     axios({
       method: 'GET',
       url: config.backendBase+lang+'jsonapi/node/info?include=field_svg_icon&sort=field_arrangement',
       headers: {
         'Content-Type': 'json',
         'X-CSRF-Token': session,
       }
     })
       .then(res => {
         //console.log(res);
         setInfos(res.data.data);
         setIcons(res.data.included);
         setLoading(false);
       })
       .catch(err => {
         //console.log(err);
         setLoading(false);
       });
   }

 }, [props.i18n.language]);





  return (
    <>

    {loading &&
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    }

    {!loading &&
      <>
      <div className="accordion" id="accordion-info">

          {infos.map(function(info, idx){

             return (

               <div className="accordion-item" key={idx}>
                 <h2 className="accordion-header" id={'heading-' + idx}>
                   <button className={`accordion-button ${idx > 0 ? "collapsed" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target={'#collapse-' + idx} aria-expanded="true" aria-controls={'collapse-' + idx}>
                     {info.relationships.field_svg_icon.data &&
                       <img src={config.backendBase + icons.find(x => x.id === info.relationships.field_svg_icon.data.id).attributes.uri.url} className="img-fluid" />
                     }

                      {info.attributes.title}
                   </button>
                 </h2>
                 <div id={'collapse-' + idx} className={`accordion-collapse collapse ${idx == 0 ? "show" : ""}`} aria-labelledby={'heading-' + idx} data-bs-parent="#accordion-info">
                   <div className="accordion-body">
                     <div dangerouslySetInnerHTML={{ __html: info.attributes.body.value }} />
                   </div>
                 </div>
               </div>

             )
           })}

      </div>
      </>
    }
    </>
  );
}

export default withTranslation()(InfoAccordions);
