import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import './assets/css/index.css';
import Ticket from "../../blocks/ticket/Ticket";
import PagePreloader from "../../helpers/PagePreloader";
import InfoAccordions from "./InfoAccordions";
import { withTranslation, Trans } from 'react-i18next';
import config from '../../config';


class Infos extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="infos">
          <div className="animatesTriangleRight">
            <TriangleAnimated />
          </div>

          <div className="animatesTriangleLeft">
            <TriangleAnimated />
          </div>

          <h1>{this.props.t('Informationen')}</h1>

          <div className="body container">
              {this.props.t('Hier findest du alle wichtigen Hinweise rund ums alba Festival. Falls noch Fragen sind, melde dich per E-Mail an info@alba-festival.ch')}
          </div>


          <div className="container">
            <InfoAccordions />
          </div>


          <Ticket />



        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Infos);
