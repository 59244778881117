import { useState, useEffect, useRef } from 'react'
import Ticker from 'react-ticker'
import { Modal } from 'bootstrap'
import HomeArtist from './HomeArtist'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'
import { withTranslation, Trans } from 'react-i18next';
import config from '../../config';

function HomeLineup(props) {


  const [speed, setSpeed] = useState(5);
  const [artist, setArtist] = useState(null);
  const [session, setSession] = useState(0);
  const [loading, setLoading] = useState(true);
  const [artists, setArtists] = useState(null);
  const [images, setImages] = useState(null);


  useEffect(async () => {

    if(!session) {
      const result = await axios(
        config.backendBase + 'session/token',
      );

      setSession(result.data);

      axios({
        method: 'GET',
        url: config.backendBase + 'jsonapi/node/artist?include=field_grid_image',
        headers: {
          'Content-Type': 'json',
          'X-CSRF-Token': session,
        }
      })
        .then(res => {
          //console.log(res);
          setArtists(res.data.data);
          setImages(res.data.included);
          setLoading(false);
        })
        .catch(err => {
          //console.log(err);
          setLoading(false);
        });
    }
  });

  function onMouseEnterHandler(artist) {
    setSpeed(1);
  }

  function onMouseLeaveHandler(artist) {
    setSpeed(5);
  }





  return (
    <>

    {loading &&
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    }

    {!loading &&
      <>
      <Ticker direction="toLeft" speed={speed}>
        {({ index }) => (
          <>
            <HomeArtist mouseEnter={onMouseEnterHandler} mouseLeave={onMouseLeaveHandler} artist={artists[Math.floor(Math.random()*artists.length)]} />
          </>
        )}
      </Ticker>
      <Ticker direction="toRight" offset="100%" speed={speed}>
        {({ index }) => (
          <>
          <HomeArtist  mouseEnter={onMouseEnterHandler} mouseLeave={onMouseLeaveHandler} artist={artists[Math.floor(Math.random()*artists.length)]} />
          </>
        )}
      </Ticker>
    <Ticker speed={speed}>
        {({ index }) => (
          <>
          <HomeArtist mouseEnter={onMouseEnterHandler} mouseLeave={onMouseLeaveHandler} artist={artists[Math.floor(Math.random()*artists.length)]} />
          </>
        )}
      </Ticker>
      <Ticker direction="toRight" offset="100%" speed={speed}>
        {({ index }) => (
          <>
          <HomeArtist mouseEnter={onMouseEnterHandler} mouseLeave={onMouseLeaveHandler} artist={artists[Math.floor(Math.random()*artists.length)]} />
          </>
        )}
      </Ticker>

      <Link to="/lineup">
        <button className="btn btn-primary">
          <FontAwesomeIcon icon={AllLightIcons.faMicrophoneStand} />  {props.t('Line-up 2021')}
        </button>
      </Link>

      </>
    }
    </>
  );
}

export default withTranslation()(HomeLineup);
