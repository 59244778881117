import React from 'react';

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import ArtistDetail from "./ArtistDetail";
import './assets/css/index.css';
import PagePreloader from "../../helpers/PagePreloader";
import { Helmet } from 'react-helmet'


class Artist extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }

  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="artist">

          <ArtistDetail />


          <Ticket />
        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default Artist;
