import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import PagePreloader from "../../helpers/PagePreloader";
import TicketsGrid from "./TicketsGrid";
import { withTranslation, Trans } from 'react-i18next';


class Tickets extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="tickets">
          <div className="animatesTriangleRight">
            <TriangleAnimated />
          </div>

          <div className="animatesTriangleLeft">
            <TriangleAnimated />
          </div>


          <h1>{this.props.t('Tickets')}</h1>

          <div className="body container">
            {this.props.t('Tickets für das alba Festival 2021 sind exklusiv bei seetickets.ch erhältlich.')}
          </div>


          <TicketsGrid  />


          <Ticket />
        </section>

        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Tickets);
