// use jsx to render html, do not modify simple.html
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import TweenOne from 'rc-tween-one';

import TextyAnim from 'rc-texty';




class HomeIntroHeadline extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text,
      interval: null,
      animationEffects : [
        'easeInOutQuint',
        'easeInOutCubic',
        'easeInOutQuart',
        'easeInOutQuad',
        'easeInOutSine'
      ]
    }




  }

  componentDidMount() {

    var component = this;

    this._isMounted = true;

    var textArray = new Array(
      'We are one',
      'We are alba',
      'Be a part of',
      '4. & 5. SEPTEMBER',
      'HARDTURM STADION',
      'best of albanian music',
      'get your tickets now'
    )

    const interval = setInterval(() => {

      if(!component._isMounted) {
        clearInterval(this.state.interval);
        return;
      }


      component.setState({
        text: textArray[Math.floor(Math.random()*textArray.length)],
        showAnimation: false
      });

      component.forceUpdate();

      component.setState({
        text: textArray[Math.floor(Math.random()*textArray.length)],
        showAnimation: true
      });

    }, this.props.delay * 5);

    if(!component._isMounted) {
      clearInterval(this.state.interval);
      return;
    }

    this.setState({
      interval: interval
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.interval);
  }

  componentDidUnmont() {
    clearInterval(this.state.interval);
  }

  geInterval = (e) => {
    switch (e.index) {
      case 0:
        return 0;
      case 1:
        return 150;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return 150 + 450 + (e.index - 2) * 10;
      default:
        return 150 + 450 + (e.index - 6) * 150;
    }
  }
  getEnter = (e) => {
    const t = {
      opacity: 0,
      scale: 0.8,
      y: '-100%',
    };
    if (e.index >= 2 && e.index <= 6) {
      return { ...t, y: '-30%', duration: 150 };
    }
    return t;
  }

  getSplit = (e) => {
    const t: string[] = e.split(' ');
    const c = [];
    t.forEach((str, i) => {
      c.push((
        <span key={`${str}-${i}`}>
          {str}
        </span>
      ));
      if (i < t.length - 1) {
        c.push(<span key={` -${i}`}> </span>);
      }
    });
    return c;
  }

  render() {
    return (
      <div className="combined">
        <div className="combined-shape">

        </div>

        {this.state.showAnimation &&
          <TextyAnim
            className="title"
            type="mask-top"
            delay={2400}
            enter={this.getEnter}
            interval={this.geInterval}
            component={TweenOne}
            componentProps={{
              animation: [
                { x: 190, type: 'set' },
                { x: 100, delay: 2500, duration: 850 },
                {
                  ease: this.state.animationEffects[Math.floor(Math.random()*this.state.animationEffects.length)],
                  duration: 500,
                  x: 0,
                },
                {
                  letterSpacing: 0,
                  delay: -1300,
                  scale: 0.7,
                  ease: this.state.animationEffects[Math.floor(Math.random()*this.state.animationEffects.length)],
                  duration: 300,
                },
                { scale: 1.1, width: '100%', delay: -2000, duration: 2000, ease: this.state.animationEffects[Math.floor(Math.random()*this.state.animationEffects.length)] },
                { scale: 1.3, width: '0%', delay: 2000, duration: 2000, ease: this.state.animationEffects[Math.floor(Math.random()*this.state.animationEffects.length)] },
              ],
            }}
          >
            {this.state.text}
          </TextyAnim>
        }



      </div>
    );
  }
}

export default HomeIntroHeadline;
