import { useState, useEffect, useRef } from 'react'
import Ticker from 'react-ticker'
import { Modal } from 'bootstrap'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

function FestivalHeadline(props) {


  const [headline, setHeadline] = useState(props.headline);

  useEffect(() => {
     setHeadline(props.headline);
  })


  return (

      <div className="festival-headline">
      <Ticker>
          {({ index }) => (
              <>
                  <h1>{headline}</h1>
              </>
          )}
      </Ticker>
      <Ticker direction="toRight" offset="100%">
          {({ index }) => (
              <>
                  <h1 className="fill">{headline}</h1>
              </>
          )}
      </Ticker>
      <Ticker>
          {({ index }) => (
              <>
                  <h1>{headline}</h1>
              </>
          )}
      </Ticker>



      </div>

  );
}

export default FestivalHeadline;
