import React from 'react';
import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import { Helmet } from 'react-helmet'
import axios from "axios";
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';
import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import './assets/css/index.css';
import config from '../../config';


class Covid extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      session: false,
      images: null,
      content: null,
      currentLang: this.props.i18n.language
    };

  }

  componentDidMount() {



    var component = this;


    console.log(config);

    if(!this.state.session) {
      const result =  axios(
        config.backendBase + '/session/token',
      );

      component.setState({
        session: result.data,
      });
    }

    this.loadPage();

  }



  componentDidUpdate(prevProps, prevState) {
    var component = this;

    //alert(prevProps.i18n.language);

    if(prevState.currentLang != this.props.i18n.language) {
      component.setState({
        currentLang: this.props.i18n.language
      });
      this.loadPage();
    }
  }

  loadPage () {

    var component = this;


      var lang = '';

      if(this.state.currentLang != 'de') {
        lang = this.state.currentLang + '/';
      }

      axios({
        method: 'GET',
        url: config.backendBase+lang+'jsonapi/node/page/ee313004-e835-4f78-b073-09af3b6f61ce',
        headers: {
          'Content-Type': 'json',
          'X-CSRF-Token': this.state.session,
        }
      })
        .then(res => {

          component.setState({
            content: res.data.data,
            images: res.data.included,
            loading: false,
          });

        })
        .catch(err => {
          console.log(err);
          component.setState({
            loading: false,
          });
        });

  }


  render() {
    return (
      <React.Fragment>


        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="covid" className="text-start page">


          <div className="animatesTriangleRight">
            <TriangleAnimated />
          </div>

          <div className="animatesTriangleLeft">
            <TriangleAnimated />
          </div>

          <div className="container">
            {this.state.loading &&
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            }

            {!this.state.loading &&
              <>
              {this.props.i18n.language == 'de' &&
                <iframe width="100%" height="546" src="https://www.youtube.com/embed/G6O4rH4QmdQ?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }

              {this.props.i18n.language == 'en' &&
                <iframe width="100%" height="546" src="https://www.youtube.com/embed/PwrnHzuGVfw?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }

              {this.props.i18n.language == 'it' &&
                <iframe width="100%" height="546" src="https://www.youtube.com/embed/PwrnHzuGVfw?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }

              {this.props.i18n.language == 'fr' &&
                <iframe width="100%" height="546" src="https://www.youtube.com/embed/PwrnHzuGVfw?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }

              {this.props.i18n.language == 'sq' &&
                <iframe width="100%" height="546" src="https://www.youtube.com/embed/rP_bJo9C_Wo?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }

              <hr />
              <div dangerouslySetInnerHTML={{ __html: this.state.content.attributes.body.value }} />
              </>
            }
          </div>

        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Covid);
