import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';


class Contact extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="contact">


          <h1>{this.props.t('Kontakt')}</h1>

          <div className="container">
            <div className="body">
              {this.props.t('Hier bist du richtig mit deinen Fragen und Anliegen. Wir helfen dir gerne weiter.')}
            </div>
          </div>

          <div className="animatesTriangleRight">
            <TriangleAnimated />
          </div>

          <div className="animatesTriangleLeft">
            <TriangleAnimated />
          </div>

          <div className="container contact-bar">
            <div className="row">
              <div className="col-lg-4">
                 <span>
                   <FontAwesomeIcon icon={AllLightIcons.faMapMarker} />
                   <strong>Time Event AG</strong><br />
                   Nüschelerstrasse 31, 8001 Zürich
                 </span>
              </div>
              <div className="col-lg-4">
                 <span>
                   <FontAwesomeIcon icon={AllLightIcons.faMapMarker} />
                   <strong>Festival Location</strong><br />
                   Hardturmstrasse 404, Zürich
                 </span>
              </div>
              <div className="col-lg-4">
                 <span>
                  <FontAwesomeIcon icon={AllLightIcons.faEnvelope} />
                  <strong>Email</strong><br />
                  info@alba-festival.ch
                 </span>
              </div>

            </div>
          </div>

          <Ticket />

        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Contact);
