import { useState, useEffect, useRef } from 'react'
import Ticker from 'react-ticker'
import { Modal } from 'bootstrap'
import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom'
import YouTube from 'react-youtube';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'


import ArtistHeadline from './ArtistHeadline';

import config from '../../config';


function ArtistDetail() {


  const opts = {
      height: '300',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

  const { id } = useParams()
  const [artist, setArtist] = useState(null);
  const [image, setImage] = useState(null);
  const [session, setSession] = useState(0);
  const [loading, setLoading] = useState(true);


  useEffect(async () => {

    if(!session) {
      const result = await axios(
        config.backendBase + 'session/token',
      );

      setSession(result.data);

      axios({
        method: 'GET',
        url: config.backendBase + 'jsonapi/node/artist/'+id + '?include=field_detail_image',
        headers: {
          'Content-Type': 'json',
          'X-CSRF-Token': session,
        }
      })
        .then(res => {
          //console.log(res);
          setArtist(res.data.data);
          setImage(res.data.included[0]);
          setLoading(false);
        })
        .catch(err => {
          //console.log(err);
          setLoading(false);
        });
    }
  });



  return (
    <>

    <div className="artist-details">

    <h1>line-up</h1>

    {loading &&
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    }

    {!loading &&
      <>
      <h2 className="hide">{artist.attributes.title}</h2>

      <div className="image-bar">
        <div className="artist-headline">
          <ArtistHeadline artist={artist} />
        </div>
        <img src={config.backendBase  + image.attributes.uri.url} className="img-fluid" />
      </div>


      <hr />


      <div className="container video-bar">
        <div className="row">
          {artist.attributes.field_videos && artist.attributes.field_videos[0] &&
            <div className="col-lg-4">
              <YouTube videoId={artist.attributes.field_videos[0]} opts={opts}  />
            </div>
          }

          {artist.attributes.field_videos && artist.attributes.field_videos[1] &&
            <div className="col-lg-4">
              <YouTube videoId={artist.attributes.field_videos[1]} opts={opts}  />
            </div>
          }

          {artist.attributes.field_videos && artist.attributes.field_videos[2] &&
            <div className="col-lg-4">
              <YouTube videoId={artist.attributes.field_videos[2]} opts={opts}  />
            </div>
          }
        </div>
      </div>


      <div className="social-bar container">
        <h3>Checkout {artist.attributes.title} on social media</h3>
        <ul
          style={{
            height: "100%",
            textAlign: 'center',
            fontSize: "1vw",
            padding: 0,
            paddingBottom: 20,
            margin: 0,
            listStyle: "none",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center"
          }}
          className="social-bar"
        >
        {artist.attributes.field_facebook &&
          <li>
            <a href={artist.attributes.field_facebook} target="_blank">
              <FontAwesomeIcon icon={AllBrandIcons.faFacebook} />
            </a>
          </li>
        }
        {artist.attributes.field_instagram &&
          <li>
            <a href={artist.attributes.field_instagram} target="_blank">
              <FontAwesomeIcon icon={AllBrandIcons.faInstagram} />
            </a>
          </li>
        }
        {artist.attributes.field_twitter &&
          <li>
            <a href={artist.attributes.field_twitter} target="_blank">
              <FontAwesomeIcon icon={AllBrandIcons.faTwitter} />
            </a>
          </li>
        }
        {artist.attributes.field_youtube_link &&
          <li>
            <a href={artist.attributes.field_youtube_link} target="_blank">
              <FontAwesomeIcon icon={AllBrandIcons.faYoutube} />
            </a>
          </li>
        }
        {artist.attributes.field_spotify &&
          <li>
            <a href={artist.attributes.field_spotify} target="_blank">
              <FontAwesomeIcon icon={AllBrandIcons.faSpotify} />
            </a>
          </li>
        }
        {artist.attributes.field_tiktok &&
        <li>
          <a href={artist.attributes.field_tiktok} target="_blank">
            <FontAwesomeIcon icon={AllBrandIcons.faTiktok} />
          </a>
        </li>
        }
        {artist.attributes.field_snapchat &&
          <li>
            <a href={artist.attributes.field_snapchat} target="_blank">
              <FontAwesomeIcon icon={AllBrandIcons.faSnapchat} />
            </a>
          </li>
        }
      </ul>

      </div>




      </>
    }

    </div>
    </>
  );
}

export default ArtistDetail;
