import { animated, config, Spring } from 'react-spring'
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";

class TriangleAnimated extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFinished: false,
      strokeOffset: Math.floor(Math.random() * (400 - 200 + 1)) + 200,
      interval: null
    }


    this.triangleRef =  React.createRef();

    this.onStart = this.onStart.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.interval);
  }

  onRest() {

  }

  onStart() {

    var component = this;

    if(!component._isMounted) {
      return;
    }

    setTimeout(function(){

      if(!component._isMounted) {
        if(component.state.interval) {
          clearInterval(component.state.interval);
        }
        return;
      }

      component.setState({
        isFinished: true
      });

      var interval = setInterval(function(){

        if(!component._isMounted) {
          clearInterval(component.state.interval);
          return;
        }

        if(ReactDOM.findDOMNode(component.triangleRef.current) == null) {
          clearInterval(component.state.interval);
          return;
        }

        var st0Elements = ReactDOM.findDOMNode(component.triangleRef.current).getElementsByClassName('st0'); // Returns the elements
        st0Elements = [...st0Elements];

        var st1Elements = ReactDOM.findDOMNode(component.triangleRef.current).getElementsByClassName('st1'); // Returns the elements
        st1Elements = [...st1Elements];

        var st2Elements = ReactDOM.findDOMNode(component.triangleRef.current).getElementsByClassName('st2'); // Returns the elements
        st2Elements = [...st2Elements];

        for (let i = 0; i < st0Elements.length; i++) {
          //console.log(st0Elements[i]);
          st0Elements[i].setAttribute("class", "st1 fill");
        }
        for (let i = 0; i < st1Elements.length; i++) {
          st1Elements[i].setAttribute("class", "st2 fill");
        }
        for (let i = 0; i < st2Elements.length; i++) {
          st2Elements[i].setAttribute("class", "st0 fill");
        }
      }, 15000);

      component.setState({
        interval: interval
      });


    }, 3100);
  }

  render() {
    return(
      <>
      <div className="triangle-block animate" ref={this.triangleRef}>

        <Spring
          reset={false}
          reverse={false}
          from={{ x: 0 }}
          x={1}
          delay={0}
          config={{
            mass:  Math.floor(Math.random() * (10 - 1 + 1)) + 1,
            tension: Math.floor(Math.random() * (30 - 1 + 1)) + 1,
            friction: Math.floor(Math.random() * (30 - 1 + 1)) + 1,
            duration: 3000,
          }}
          onRest={this.onRest}
          onStart={this.onStart}
        >
          {({ x }) => (

      <animated.svg
      strokeWidth="0.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray={this.state.strokeOffset}
      viewBox="0 0 303.3 303.3"
      strokeDashoffset={x.to(x => (1 + x) * this.state.strokeOffset)}>
        <g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="110.6,120.3 55.8,120.3 57.5,117.2 108.8,117.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="105.4,111.4 60.9,111.4 62.7,108.4 103.7,108.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="100.3,102.5 66,102.5 67.8,99.5 98.6,99.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="95.2,93.6 71.2,93.6 72.9,90.6 93.4,90.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="90.1,84.8 76.3,84.8 78,81.7 88.3,81.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="84.9,75.9 81.4,75.9 83.2,72.8 		"/>
          </g>
          <g>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="110.6,215.2 55.8,215.2 64.2,200.6 102.2,200.6 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="101.1,198.7 65.3,198.7 73.7,184.2 92.7,184.2 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="91.6,182.3 74.8,182.3 83.2,167.7 		"/>
          </g>
          <g>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="137.9,262.7 83.1,262.7 83.1,262.7 84.4,260.5 136.7,260.5 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="134.2,256.2 86.9,256.2 88.1,254 132.9,254 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="130.5,249.7 90.6,249.7 91.9,247.6 129.2,247.6 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="126.7,243.3 94.4,243.3 95.6,241.1 125.5,241.1 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="123,236.8 98.1,236.8 99.3,234.6 121.7,234.6 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="119.3,230.3 101.8,230.3 103.1,228.2 118,228.2 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="115.5,223.9 105.6,223.9 106.8,221.7 114.3,221.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="111.8,217.4 109.3,217.4 110.5,215.2 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="165.3,215.2 192.7,167.7 197.3,175.7 174.5,215.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="176.8,215.2 198.4,177.6 203,185.5 185.9,215.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="188.2,215.2 204.1,187.5 208.7,195.4 197.3,215.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="199.6,215.2 209.9,197.4 214.4,205.3 208.7,215.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="211,215.2 215.6,207.3 220.1,215.2 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="192.7,72.8 220.1,25.4 220.1,25.4 221.4,27.6 195.2,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="198.5,72.8 223,30.4 224.3,32.6 201,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="204.4,72.8 225.9,35.5 227.2,37.6 206.9,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="210.2,72.8 241.1,19.4 242.4,21.5 212.7,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="216,72.8 244,24.4 245.3,26.5 218.5,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="221.8,72.8 234.7,50.6 235.9,52.7 224.3,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="227.6,72.8 237.6,55.6 238.8,57.8 230.1,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="233.4,72.8 240.5,60.6 241.7,62.8 235.9,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="239.2,72.8 243.4,65.7 244.6,67.8 241.7,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="247.5,72.8 245,72.8 246.3,70.7 247.5,72.8 		"/>
          </g>
          <g>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="220.1,120.3 247.5,72.8 248.6,74.7 222.3,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="223.6,120.3 249.3,75.9 250.3,77.7 225.8,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="227.2,120.3 251,78.9 252.1,80.8 229.3,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="230.7,120.3 252.8,82 253.9,83.8 232.8,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="234.2,120.3 254.5,85 255.6,86.9 236.3,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="237.7,120.3 256.3,88 257.4,89.9 239.8,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="241.2,120.3 258.1,91.1 259.1,92.9 243.3,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="244.7,120.3 259.8,94.1 260.9,96 246.8,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="248.2,120.3 261.6,97.2 262.6,99 250.3,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="251.7,120.3 263.3,100.2 264.4,102.1 253.9,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="255.2,120.3 265.1,103.2 266.1,105.1 257.4,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="258.7,120.3 266.8,106.3 267.9,108.1 260.9,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="262.3,120.3 268.6,109.3 269.6,111.2 264.4,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="265.8,120.3 270.3,112.4 271.4,114.2 267.9,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="269.3,120.3 272.1,115.4 273.2,117.2 271.4,120.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="272.8,120.3 273.8,118.4 274.9,120.3 		"/>
          </g>
          <g>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="138,167.7 138.2,168.2 111.1,215.2 110.6,215.2 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="113,215.2 112.5,215.2 138.9,169.4 139.2,169.9 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="140.2,171.5 115,215.2 114.4,215.2 139.9,171.1 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="141.1,173.2 116.9,215.2 116.4,215.2 140.9,172.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="142.1,174.9 118.8,215.2 118.3,215.2 141.8,174.4 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="143.1,176.6 120.8,215.2 120.3,215.2 142.8,176.1 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="144,178.2 122.7,215.2 122.2,215.2 143.8,177.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="124.6,215.2 124.1,215.2 144.7,179.5 145,179.9 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="126.6,215.2 126.1,215.2 145.7,181.2 146,181.6 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="146.9,183.3 128.5,215.2 128,215.2 146.7,182.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="147.9,185 130.5,215.2 129.9,215.2 147.6,184.5 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="148.9,186.6 132.4,215.2 131.9,215.2 148.6,186.2 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="149.8,188.3 134.3,215.2 133.8,215.2 149.6,187.9 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="150.8,190 136.3,215.2 135.8,215.2 150.6,189.6 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="151.8,191.7 138.2,215.2 137.7,215.2 151.5,191.2 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="140.2,215.2 139.6,215.2 152.5,192.9 152.8,193.4 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="142.1,215.2 141.6,215.2 153.5,194.6 153.7,195 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="144,215.2 143.5,215.2 154.4,196.3 154.7,196.7 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="146,215.2 145.5,215.2 155.4,197.9 155.7,198.4 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="147.9,215.2 147.4,215.2 156.4,199.6 156.6,200.1 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="149.8,215.2 149.3,215.2 157.3,201.3 157.6,201.7 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="151.8,215.2 151.3,215.2 158.3,203 158.6,203.4 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="153.7,215.2 153.2,215.2 159.3,204.7 159.5,205.1 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="155.7,215.2 155.1,215.2 160.2,206.3 160.5,206.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="157.6,215.2 157.1,215.2 161.2,208 161.5,208.5 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="159.5,215.2 159,215.2 162.2,209.7 162.4,210.1 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="161.5,215.2 161,215.2 163.2,211.4 163.4,211.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="163.4,215.2 162.9,215.2 164.1,213.1 164.4,213.5 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="164.8,215.2 165.1,214.7 165.3,215.2 		"/>
          </g>
          <g>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="83.2,167.7 110.6,120.3 110.6,120.3 111.8,122.5 85.7,167.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="90.6,167.7 114.3,126.8 115.5,128.9 93.1,167.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="98.1,167.7 118,133.2 119.3,135.4 100.6,167.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="105.6,167.7 121.8,139.7 123,141.9 108.1,167.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="113.1,167.7 125.5,146.2 126.8,148.3 115.5,167.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="120.5,167.7 129.2,152.6 130.5,154.8 123,167.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="128,167.7 133,159.1 134.2,161.3 130.5,167.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="135.5,167.7 136.7,165.6 138,167.7 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="220.1,120.3 247.5,167.7 247.5,167.7 246.8,167.7 219.8,120.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="219.5,121.5 246.2,167.7 245.5,167.7 219.1,122.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="218.8,122.6 244.8,167.7 244.1,167.7 218.4,123.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="218.1,123.8 243.5,167.7 242.8,167.7 217.8,124.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="217.4,125 242.1,167.7 241.4,167.7 217.1,125.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="216.8,126.1 240.8,167.7 240.1,167.7 216.4,126.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="216.1,127.3 239.4,167.7 238.7,167.7 215.7,127.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="215.4,128.5 238.1,167.7 237.4,167.7 215.1,129.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="214.7,129.7 236.7,167.7 236,167.7 214.4,130.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="214,130.8 235.4,167.7 234.7,167.7 213.7,131.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="213.4,132 234,167.7 233.3,167.7 213,132.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="212.7,133.2 232.6,167.7 232,167.7 212.3,133.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="212,134.3 231.3,167.7 230.6,167.7 211.7,134.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="211.3,135.5 229.9,167.7 229.3,167.7 211,136.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="210.7,136.7 228.6,167.7 227.9,167.7 210.3,137.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="210,137.9 227.2,167.7 226.5,167.7 209.6,138.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="209.3,139 225.9,167.7 225.2,167.7 209,139.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="208.6,140.2 224.5,167.7 223.8,167.7 208.3,140.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="208,141.4 223.2,167.7 222.5,167.7 207.6,142 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="207.3,142.5 221.8,167.7 221.1,167.7 206.9,143.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="206.6,143.7 220.5,167.7 219.8,167.7 206.3,144.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="205.9,144.9 219.1,167.7 218.4,167.7 205.6,145.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="205.3,146.1 217.8,167.7 217.1,167.7 204.9,146.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="204.6,147.2 216.4,167.7 215.7,167.7 204.2,147.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="203.9,148.4 215.1,167.7 214.4,167.7 203.6,149 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="203.2,149.6 213.7,167.7 213,167.7 202.9,150.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="202.6,150.7 212.4,167.7 211.7,167.7 202.2,151.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="201.9,151.9 211,167.7 210.3,167.7 201.5,152.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="201.2,153.1 209.7,167.7 209,167.7 200.9,153.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="200.5,154.3 208.3,167.7 207.6,167.7 200.2,154.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="199.8,155.4 207,167.7 206.3,167.7 199.5,156 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="199.2,156.6 205.6,167.7 204.9,167.7 198.8,157.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="198.5,157.8 204.2,167.7 203.6,167.7 198.1,158.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="197.8,158.9 202.9,167.7 202.2,167.7 197.5,159.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="197.1,160.1 201.5,167.7 200.9,167.7 196.8,160.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="196.5,161.3 200.2,167.7 199.5,167.7 196.1,161.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="195.8,162.5 198.8,167.7 198.1,167.7 195.4,163 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="195.1,163.6 197.5,167.7 196.8,167.7 194.8,164.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="194.4,164.8 196.1,167.7 195.4,167.7 194.1,165.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="193.8,166 194.8,167.7 194.1,167.7 193.4,166.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="192.7,167.7 193.1,167.1 193.4,167.7 192.7,167.7 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="192.7,72.8 220.1,120.3 220.1,120.3 217.6,120.3 191.5,75 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="189.8,77.9 214.3,120.3 211.8,120.3 188.6,80 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="186.9,82.9 208.5,120.3 206,120.3 185.7,85.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="184,87.9 202.7,120.3 200.2,120.3 182.8,90.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="181.1,93 196.9,120.3 194.4,120.3 179.9,95.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="178.2,98 191.1,120.3 188.6,120.3 177,100.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="175.3,103 185.3,120.3 182.8,120.3 174.1,105.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="172.4,108.1 179.5,120.3 177,120.3 171.2,110.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="169.5,113.1 173.7,120.3 171.2,120.3 168.3,115.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="165.3,120.3 166.6,118.1 167.8,120.3 165.3,120.3 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="165.3,120.3 192.7,167.7 190.6,167.7 164.3,122.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="163.6,123.3 189.2,167.7 187.1,167.7 162.5,125.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="161.8,126.4 185.7,167.7 183.6,167.7 160.8,128.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="160.1,129.4 182.2,167.7 180.1,167.7 159,131.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="158.3,132.4 178.7,167.7 176.6,167.7 157.3,134.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="156.6,135.5 175.2,167.7 173.1,167.7 155.5,137.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="154.8,138.5 171.7,167.7 169.5,167.7 153.8,140.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="153.1,141.6 168.2,167.7 166,167.7 152,143.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="151.3,144.6 164.7,167.7 162.5,167.7 150.2,146.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="149.6,147.6 161.1,167.7 159,167.7 148.5,149.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="147.8,150.7 157.6,167.7 155.5,167.7 146.7,152.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="146,153.7 154.1,167.7 152,167.7 145,155.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="144.3,156.8 150.6,167.7 148.5,167.7 143.2,158.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="142.5,159.8 147.1,167.7 145,167.7 141.5,161.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="140.8,162.8 143.6,167.7 141.5,167.7 139.7,164.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="139,165.9 140.1,167.7 138,167.7 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="28.4,72.8 55.8,120.3 55.3,120.3 28.1,73.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="27.4,74.5 53.8,120.3 53.3,120.3 27.2,75 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="26.5,76.2 51.9,120.3 51.4,120.3 26.2,76.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="25.5,77.9 50,120.3 49.5,120.3 25.2,78.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="24.5,79.6 48,120.3 47.5,120.3 24.3,80 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="23.5,81.2 46.1,120.3 45.6,120.3 23.3,81.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="22.6,82.9 44.2,120.3 43.6,120.3 22.3,83.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="21.6,84.6 42.2,120.3 41.7,120.3 21.4,85 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="20.6,86.3 40.3,120.3 39.8,120.3 20.4,86.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="19.7,88 38.3,120.3 37.8,120.3 19.4,88.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="18.7,89.6 36.4,120.3 35.9,120.3 18.4,90.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="17.7,91.3 34.5,120.3 34,120.3 17.5,91.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="16.8,93 32.5,120.3 32,120.3 16.5,93.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="15.8,94.7 30.6,120.3 30.1,120.3 15.5,95.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="14.8,96.3 28.6,120.3 28.1,120.3 14.6,96.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="13.9,98 26.7,120.3 26.2,120.3 13.6,98.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="12.9,99.7 24.8,120.3 24.3,120.3 12.6,100.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="11.9,101.4 22.8,120.3 22.3,120.3 11.7,101.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="11,103.1 20.9,120.3 20.4,120.3 10.7,103.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="10,104.7 19,120.3 18.4,120.3 9.7,105.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="9,106.4 17,120.3 16.5,120.3 8.8,106.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="8,108.1 15.1,120.3 14.6,120.3 7.8,108.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="7.1,109.8 13.1,120.3 12.6,120.3 6.8,110.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="6.1,111.5 11.2,120.3 10.7,120.3 5.8,111.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="5.1,113.1 9.3,120.3 8.8,120.3 4.9,113.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="4.2,114.8 7.3,120.3 6.8,120.3 3.9,115.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="3.2,116.5 5.4,120.3 4.9,120.3 2.9,116.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="2.2,118.2 3.5,120.3 2.9,120.3 2,118.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="1.3,119.8 1.5,120.3 1,120.3 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="83.2,167.7 110.6,215.2 115.1,207.3 92.3,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="94.6,167.7 116.3,205.3 120.8,197.4 103.7,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="106,167.7 122,195.4 126.6,187.5 115.1,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="117.4,167.7 127.7,185.5 132.3,177.6 126.6,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="128.8,167.7 133.4,175.7 138,167.7 		"/>
          </g>
          <g>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="28.4,72.8 55.8,120.3 56.1,119.7 29.1,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="32.6,72.8 57.9,116.7 58.2,116.1 33.2,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="36.7,72.8 59.9,113.1 60.3,112.5 37.4,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="40.9,72.8 62,109.5 62.4,108.9 41.6,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="45,72.8 64.1,105.9 64.5,105.3 45.7,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="49.2,72.8 66.2,102.3 66.5,101.7 49.9,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="53.4,72.8 68.3,98.7 68.6,98.1 54,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="57.5,72.8 70.3,95.1 70.7,94.5 58.2,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="61.7,72.8 72.4,91.5 72.8,90.9 62.4,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="65.8,72.8 74.5,87.9 74.9,87.3 66.5,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="70,72.8 76.6,84.3 76.9,83.7 70.7,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="74.2,72.8 78.7,80.7 79,80.1 74.9,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="78.3,72.8 80.8,77 81.1,76.4 79,72.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="82.5,72.8 82.8,73.4 83.2,72.8 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="28.2,167.7 55.7,215.3 56,214.9 28.7,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="30.2,167.7 56.7,213.6 56.9,213.2 30.7,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="32.1,167.7 57.6,212 57.9,211.5 32.6,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="34.1,167.7 58.6,210.3 58.9,209.8 34.6,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="36,167.7 59.6,208.6 59.8,208.1 36.5,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="37.9,167.7 60.6,206.9 60.8,206.5 38.5,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="39.9,167.7 61.5,205.2 61.8,204.8 40.4,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="41.8,167.7 62.5,203.5 62.8,203.1 42.3,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="43.8,167.7 63.5,201.9 63.7,201.4 44.3,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="45.7,167.7 64.4,200.2 64.7,199.7 46.2,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="47.7,167.7 65.4,198.5 65.7,198 48.2,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="49.6,167.7 66.4,196.8 66.7,196.4 50.1,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="51.6,167.7 67.4,195.1 67.6,194.7 52.1,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="53.5,167.7 68.3,193.4 68.6,193 54,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="55.4,167.7 69.3,191.8 69.6,191.3 56,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="57.4,167.7 70.3,190.1 70.5,189.6 57.9,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="59.3,167.7 71.3,188.4 71.5,187.9 59.8,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="61.3,167.7 72.2,186.7 72.5,186.3 61.8,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="63.2,167.7 73.2,185 73.5,184.6 63.7,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="65.2,167.7 74.2,183.3 74.4,182.9 65.7,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="67.1,167.7 75.1,181.6 75.4,181.2 67.6,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="69.1,167.7 76.1,180 76.4,179.5 69.6,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="71,167.7 77.1,178.3 77.3,177.8 71.5,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="72.9,167.7 78.1,176.6 78.3,176.2 73.5,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="74.9,167.7 79,174.9 79.3,174.5 75.4,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="76.8,167.7 80,173.2 80.3,172.8 77.3,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="78.8,167.7 81,171.5 81.2,171.1 79.3,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="80.7,167.7 81.9,169.9 82.2,169.4 81.2,167.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="82.7,167.7 82.9,168.2 83.2,167.7 		"/>
          </g>
          <g>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="247.5,167.7 274.9,120.3 271.4,120.3 245.8,164.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="224.2,190.5 264.7,120.3 261.1,120.3 222.4,187.5 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="237.3,150 254.4,120.3 250.9,120.3 235.5,146.9 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="232.1,141.1 244.1,120.3 240.6,120.3 230.4,138.1 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="227,132.2 233.9,120.3 230.4,120.3 225.3,129.2 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="221.9,123.3 223.6,120.3 220.1,120.3 		"/>
          </g>
          <g>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="165.3,120.3 192.7,72.8 192.7,72.8 189.3,72.8 163.6,117.3 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="163.2,116.6 188.5,72.8 185.1,72.8 161.5,113.6 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="161.1,112.9 184.2,72.8 180.8,72.8 159.4,109.9 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="158.9,109.2 179.9,72.8 176.5,72.8 157.2,106.2 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="156.8,105.5 175.6,72.8 172.2,72.8 155.1,102.5 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="154.6,101.7 171.3,72.8 167.9,72.8 152.9,98.8 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="152.5,98 194.7,24.7 191.3,24.7 150.8,95.1 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="150.4,94.3 176.5,49 173.1,49 148.7,91.4 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="148.2,90.6 158.5,72.8 155.1,72.8 146.5,87.7 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="146.1,86.9 154.2,72.8 150.8,72.8 144.4,84 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="134.9,98.8 149.9,72.8 146.5,72.8 133.2,95.9 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="141.8,79.5 145.6,72.8 142.2,72.8 140.1,76.6 		"/>
            <polygon className={`st2 ${this.state.isFinished ? "fill" : ""}`} points="139.7,75.8 141.4,72.8 138,72.8 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="137.9,262.7 165.3,215.2 163.2,215.2 136.9,260.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="136.2,259.6 161.8,215.2 159.7,215.2 135.1,257.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="134.4,256.6 158.3,215.2 156.2,215.2 133.4,254.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="132.7,253.5 154.8,215.2 152.7,215.2 131.6,251.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="130.9,250.5 151.3,215.2 149.2,215.2 129.8,248.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="110.2,280.5 147.8,215.2 145.6,215.2 109.2,278.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="127.4,244.4 144.3,215.2 142.1,215.2 126.3,242.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="125.6,241.4 140.8,215.2 138.6,215.2 124.6,239.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="123.9,238.3 137.2,215.2 135.1,215.2 122.8,236.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="122.1,235.3 133.7,215.2 131.6,215.2 121.1,233.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="120.4,232.3 130.2,215.2 128.1,215.2 119.3,230.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="118.6,229.2 126.7,215.2 124.6,215.2 117.6,227.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="116.9,226.2 123.2,215.2 121.1,215.2 115.8,224.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="115.1,223.1 119.7,215.2 117.6,215.2 114.1,221.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="113.4,220.1 116.2,215.2 114.1,215.2 112.3,218.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="111.6,217.1 112.7,215.2 110.5,215.2 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="274.9,120.3 274.7,119.8 301.8,72.8 302.3,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="273.9,118.6 273.7,118.2 299.9,72.8 300.4,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="273,116.9 272.7,116.5 297.9,72.8 298.4,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="272,115.3 271.7,114.8 296,72.8 296.5,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="271,113.6 270.8,113.1 294,72.8 294.6,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="270.1,111.9 269.8,111.5 292.1,72.8 292.6,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="269.1,110.2 268.8,109.8 290.2,72.8 290.7,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="288.7,72.8 268.1,108.5 267.9,108.1 288.2,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="286.8,72.8 267.2,106.9 266.9,106.4 286.3,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="266.2,105.2 265.9,104.7 284.3,72.8 284.9,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="265.2,103.5 265,103.1 282.4,72.8 282.9,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="264.3,101.8 264,101.4 280.5,72.8 281,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="263.3,100.1 263,99.7 278.5,72.8 279,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="262.3,98.5 262.1,98 276.6,72.8 277.1,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="261.3,96.8 261.1,96.3 274.7,72.8 275.2,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="260.4,95.1 260.1,94.7 272.7,72.8 273.2,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="259.4,93.4 259.2,93 281.7,53.9 282.2,53.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="258.4,91.8 258.2,91.3 279.8,53.9 280.3,53.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="257.5,90.1 257.2,89.6 277.8,53.9 278.3,53.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="256.5,88.4 256.2,88 275.9,53.9 276.4,53.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="255.5,86.7 255.3,86.3 263,72.8 263.5,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="254.6,85 254.3,84.6 261.1,72.8 261.6,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="253.6,83.4 253.3,82.9 259.2,72.8 259.7,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="252.6,81.7 252.4,81.2 257.2,72.8 257.7,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="251.7,80 251.4,79.6 255.3,72.8 255.8,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="250.7,78.3 250.4,77.9 253.3,72.8 253.9,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="249.7,76.6 249.5,76.2 251.4,72.8 251.9,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="248.7,75 248.5,74.5 249.5,72.8 250,72.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="248,72.8 247.8,73.3 247.5,72.8 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="83.2,262.6 110.6,215.2 110.6,215.2 108.1,215.2 81.9,260.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="79.4,256.2 103.1,215.2 100.6,215.2 78.2,254 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="65.1,268.3 95.6,215.2 93.1,215.2 63.8,266.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="52.6,276.9 88.2,215.2 85.7,215.2 51.3,274.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="48.8,270.5 80.7,215.2 78.2,215.2 47.6,268.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="64.5,230.3 73.2,215.2 70.7,215.2 63.3,228.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="60.8,223.8 65.8,215.2 63.3,215.2 59.5,221.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="57,217.3 58.3,215.2 55.8,215.2 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="110.6,120.3 55.8,120.3 55.8,120.3 56.1,120.9 110.2,120.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="109.9,121.5 56.5,121.5 56.8,122.1 109.5,122.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="109.2,122.6 57.1,122.6 57.5,123.2 108.9,123.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="108.5,123.8 57.8,123.8 58.2,124.4 108.2,124.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="107.9,125 58.5,125 58.8,125.6 107.5,125.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="107.2,126.1 59.2,126.1 59.5,126.7 106.8,126.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="106.5,127.3 59.8,127.3 60.2,127.9 106.2,127.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="105.8,128.5 60.5,128.5 60.9,129.1 105.5,129.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="105.2,129.7 61.2,129.7 61.5,130.3 104.8,130.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="104.5,130.8 61.9,130.8 62.2,131.4 104.1,131.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="103.8,132 62.5,132 62.9,132.6 103.5,132.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="103.1,133.2 63.2,133.2 63.6,133.8 102.8,133.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="102.5,134.3 63.9,134.3 64.2,134.9 102.1,134.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="101.8,135.5 64.6,135.5 64.9,136.1 101.4,136.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="101.1,136.7 65.3,136.7 65.6,137.3 100.8,137.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="100.4,137.9 65.9,137.9 66.3,138.5 100.1,138.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="99.7,139 66.6,139 66.9,139.6 99.4,139.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="99.1,140.2 67.3,140.2 67.6,140.8 98.7,140.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="98.4,141.4 68,141.4 68.3,142 98.1,142 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="97.7,142.5 68.6,142.5 69,143.1 97.4,143.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="97,143.7 69.3,143.7 69.7,144.3 96.7,144.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="96.4,144.9 70,144.9 70.3,145.5 96,145.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="95.7,146.1 70.7,146.1 71,146.7 95.3,146.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="95,147.2 71.3,147.2 71.7,147.8 94.7,147.8 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="94.3,148.4 72,148.4 72.4,149 94,149 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="93.7,149.6 72.7,149.6 73,150.2 93.3,150.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="93,150.7 73.4,150.7 73.7,151.3 92.6,151.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="92.3,151.9 74,151.9 74.4,152.5 92,152.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="91.6,153.1 74.7,153.1 75.1,153.7 91.3,153.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="91,154.3 75.4,154.3 75.7,154.9 90.6,154.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="90.3,155.4 76.1,155.4 76.4,156 89.9,156 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="89.6,156.6 76.7,156.6 77.1,157.2 89.3,157.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="88.9,157.8 77.4,157.8 77.8,158.4 88.6,158.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="88.3,158.9 78.1,158.9 78.4,159.5 87.9,159.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="87.6,160.1 78.8,160.1 79.1,160.7 87.2,160.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="86.9,161.3 79.4,161.3 79.8,161.9 86.6,161.9 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="86.2,162.5 80.1,162.5 80.5,163 85.9,163 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="85.5,163.6 80.8,163.6 81.1,164.2 85.2,164.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="84.9,164.8 81.5,164.8 81.8,165.4 84.5,165.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="84.2,166 82.2,166 82.5,166.6 83.9,166.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="83.2,167.7 83.5,167.1 82.8,167.1 83.2,167.7 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="55.8,120.3 1,120.3 1,120.3 2.3,122.5 54.5,122.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="52.9,125.3 3.9,125.3 5.2,127.5 51.6,127.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="50,130.4 6.8,130.4 8.1,132.5 48.7,132.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="47.1,135.4 9.7,135.4 11,137.5 45.8,137.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="44.2,140.4 12.6,140.4 13.9,142.6 42.9,142.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="41.3,145.4 15.5,145.4 16.8,147.6 40,147.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="38.4,150.5 18.4,150.5 19.7,152.6 37.1,152.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="35.5,155.5 21.3,155.5 22.6,157.7 34.2,157.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="32.5,160.5 24.2,160.5 25.5,162.7 31.3,162.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="28.4,167.7 29.6,165.6 27.1,165.6 28.4,167.7 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="165.3,120.3 110.6,120.3 111.6,122.1 164.3,122.1 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="163.6,123.3 112.3,123.3 113.4,125.2 162.5,125.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="161.8,126.4 114.1,126.4 115.1,128.2 160.8,128.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="160.1,129.4 115.8,129.4 116.9,131.3 159,131.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="158.3,132.4 117.6,132.4 118.7,134.3 157.3,134.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="156.6,135.5 119.3,135.5 120.4,137.3 155.5,137.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="154.8,138.5 121.1,138.5 122.2,140.4 153.8,140.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="153.1,141.6 122.9,141.6 123.9,143.4 152,143.4 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="151.3,144.6 124.6,144.6 125.7,146.5 150.2,146.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="149.6,147.6 126.4,147.6 127.4,149.5 148.5,149.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="147.8,150.7 128.1,150.7 129.2,152.5 146.7,152.5 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="146,153.7 129.9,153.7 130.9,155.6 145,155.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="144.3,156.8 131.6,156.8 132.7,158.6 143.2,158.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="142.5,159.8 133.4,159.8 134.4,161.7 141.5,161.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="140.8,162.8 135.1,162.8 136.2,164.7 139.7,164.7 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="139,165.9 136.9,165.9 138,167.7 		"/>
          </g>
          <g>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="192.7,167.7 138,167.7 138.2,168.2 192.5,168.2 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="191.8,169.4 138.9,169.4 139.2,169.9 191.5,169.9 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="190.8,171.1 139.9,171.1 140.2,171.5 190.5,171.5 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="189.8,172.8 140.9,172.8 141.1,173.2 189.6,173.2 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="188.9,174.4 141.8,174.4 142.1,174.9 188.6,174.9 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="187.9,176.1 142.8,176.1 143.1,176.6 187.6,176.6 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="186.9,177.8 143.8,177.8 144,178.2 186.7,178.2 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="186,179.5 144.7,179.5 145,179.9 185.7,179.9 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="185,181.2 145.7,181.2 146,181.6 184.7,181.6 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="184,182.8 146.7,182.8 146.9,183.3 183.8,183.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="183,184.5 147.6,184.5 147.9,185 182.8,185 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="182.1,186.2 148.6,186.2 148.9,186.6 181.8,186.6 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="181.1,187.9 149.6,187.9 149.8,188.3 180.9,188.3 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="180.1,189.6 150.6,189.6 150.8,190 179.9,190 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="179.2,191.2 151.5,191.2 151.8,191.7 178.9,191.7 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="178.2,192.9 152.5,192.9 152.8,193.4 177.9,193.4 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="177.2,194.6 153.5,194.6 153.7,195 177,195 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="176.3,196.3 154.4,196.3 154.7,196.7 176,196.7 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="175.3,197.9 155.4,197.9 155.7,198.4 175,198.4 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="174.3,199.6 156.4,199.6 156.6,200.1 174.1,200.1 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="173.4,201.3 157.3,201.3 157.6,201.7 173.1,201.7 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="172.4,203 158.3,203 158.6,203.4 172.1,203.4 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="171.4,204.7 159.3,204.7 159.5,205.1 171.2,205.1 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="170.5,206.3 160.2,206.3 160.5,206.8 170.2,206.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="169.5,208 161.2,208 161.5,208.5 169.2,208.5 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="168.5,209.7 162.2,209.7 162.4,210.1 168.3,210.1 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="167.5,211.4 163.2,211.4 163.4,211.8 167.3,211.8 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="166.6,213.1 164.1,213.1 164.4,213.5 166.3,213.5 		"/>
            <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="165.6,214.7 165.1,214.7 165.3,215.2 		"/>
          </g>
          <g>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="226.1,109.9 214.1,109.9 220.1,120.3 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="247.5,72.8 192.7,72.8 198.7,83.2 241.5,83.2 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="240.4,85.2 199.9,85.2 205.9,95.6 234.4,95.6 		"/>
            <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="233.3,97.6 207,97.6 213,107.9 227.3,107.9 		"/>
          </g>
          <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="18,81.3 28.2,63.5 24.8,63.5 16.3,78.3 	"/>
          <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="50.8,254.1 61,236.4 57.6,236.4 49.1,251.2 	"/>
          <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="281.1,144.5 291.4,126.7 287.9,126.7 279.4,141.6 	"/>
          <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="274.9,143.4 279.1,136.2 277.7,136.2 274.2,142.2 	"/>
          <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="197.5,19.6 201.7,12.4 200.3,12.4 196.8,18.4 	"/>
          <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="44.7,290.9 48.9,283.7 47.5,283.7 44,289.7 	"/>
          <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="127.9,27.1 132.1,19.9 130.7,19.9 127.2,25.9 	"/>
          <polygon className={`st0 ${this.state.isFinished ? "fill" : ""}`} points="6.6,97.2 10.8,89.9 9.4,89.9 5.9,96 	"/>
          <polygon className={`st1 ${this.state.isFinished ? "fill" : ""}`} points="168.5,228 172.7,220.8 171.3,220.8 167.8,226.8 	"/>

        </g>
      </animated.svg>
    )}
      </Spring>
      </div>
      </>
    )
  }



}


export default TriangleAnimated;
