import { useState, useEffect, useRef } from 'react'
import { Modal } from 'bootstrap'
import { Link } from "react-router-dom";



function HomeArtist(props) {


  //console.log(props);

  var linkHref = '#';

  if(props.artist.attributes.title != 'Stay tuned') {
    linkHref = 'artist/' + props.artist.id;
  }

  return (
    <>



    <h6 onMouseEnter={() => { props.mouseEnter(props.artist) }} onMouseLeave={() => { props.mouseLeave(props.artist) }}>
      <Link to={linkHref}>{props.artist.attributes.title}</Link>
    </h6>
    </>
  );
}

export default HomeArtist;
