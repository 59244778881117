// Import React
import * as React from 'react';

import CustomerMarker from '../../assets/images/map-marker-duotone.svg';

// Import necessary components for React Google Maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps'

// Import custom styles to customize the style of Google Map
const styles = require('./GoogleMapStyles.json')

// Import custom icon for map marker
// You can use this if you need to support IE11 and lower.
// const mapMarker = require('./GoogleMapMarker.svg')

// Google Map component
const FooterMap = withScriptjs(
  withGoogleMap(props => (

    <GoogleMap
      defaultZoom={15}
      defaultCenter={{
        lat: 47.3941147, // latitude for the center of the map
        lng: 8.4965303 // longitude for the center of the map
      }}
      defaultOptions={{
        disableDefaultUI: true, // disable default map UI
        draggable: true, // make map draggable
        keyboardShortcuts: false, // disable keyboard shortcuts
        scaleControl: true, // allow scale controle
        scrollwheel: false, // allow scroll wheel
        styles: styles // change default map styles
      }}
    >
      <Marker
        onClick={() => window.open('https://www.google.com/maps/dir//alba+Festival,+Hardturmstrasse+269,+8005+Z%C3%BCrich/@47.3935838,8.5027792,17z/data=!4m16!1m6!3m5!1s0x47900b1baef8a5dd:0x41f2fd8080b76f95!2salba+Festival!8m2!3d47.3935802!4d8.5049679!4m8!1m0!1m5!1m1!1s0x47900b1baef8a5dd:0x41f2fd8080b76f95!2m2!1d8.5049679!2d47.3935802!3e3', '_blank')}
        position={{
          lat: 47.3941147, // latitude for the center of the map
          lng: 8.4965303 // longitude for the center of the map
        }}
      />
    </GoogleMap>
  ))
)


// Export Google Map component
export default FooterMap;
