import React, {useState} from 'react';
import Countdown from '../../modules/countdown/Countdown';
import { useSpring, animated, config } from 'react-spring'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { withTranslation, Trans } from 'react-i18next';



function HomeCountdown(props) {

 
  const propsSpring = useSpring({
     opacity: 1,
     delay: 1000,
     transform: "translate(0px, 0px)",
     from: { opacity: 0, transform: "translate(0%, 1000%)" },
     config: config.slow
   });

  return (

    <animated.div style={propsSpring} className="countdown-bar">

      <h2>4. & 5.</h2>
      <h2>{props.t('September')}</h2>
      <h3>2021</h3>

      <Countdown
        timeTillDate="2021-09-04 13:00"
        timeFormat="MM DD YYYY , h:mm a "
      />

    </animated.div>

  );
}

export default withTranslation()(HomeCountdown);
