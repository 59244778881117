import React from 'react';
import { Helmet } from 'react-helmet'


import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import IntroBg from "../../assets/images/homeIntroBg.jpeg";
import './assets/css/index.css';




import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import Ticket from "../../blocks/ticket/Ticket";

import HeaderVideo from "../../assets/videos/headerVideo.mp4";


import HomeCountdownRefund from "./HomeCountdownRefund";
import HomeCovid from "./HomeCovid";
import HomeIntroHeadline from "./HomeIntroHeadline";
import HomeScrollButton from "./HomeScrollButton";
import HomeLineup from "./HomeLineup";

import ScrollAnimation from 'react-animate-on-scroll';
import PagePreloader from "../../helpers/PagePreloader";

import { withTranslation, Trans } from 'react-i18next';




class HomeRefund extends React.Component {

  constructor(props) {
    super(props);

    //console.log(props);

    this.state = {
      loading: true,
    };

    this.homeWelcomeRef = React.createRef()

  }


  componentDidMount() {
    this.setState({ loading: false });

  }




  render() {


    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="home">

          <div className="intro-bar">

            <div className="video-bar">
              <video playsInline autoPlay muted loop preload="metadata" className="H_i_h" data-automation="VideoPlayer_video_video">
                <source src={HeaderVideo} type="video/webm" />
                <source src={HeaderVideo} type="video/mp4" />
                <source src={HeaderVideo} type="video/ogg" />
              </video>
            </div>

            <div className="stroke-headline-bar-01">
              <HomeIntroHeadline text="We are one." delay="1500" />
            </div>

            <div className="stroke-headline-bar-02">
              <HomeIntroHeadline text="We are alba." delay="2500" />
            </div>

            <HomeCovid />

            <HomeCountdownRefund />

            <div className="arrow-bar">
              <HomeScrollButton scrollTo={this.homeWelcomeRef} />
            </div>


            <div className="bg-bar">
              <img src={IntroBg} />
            </div>


            <div className="triangle-bar">
              <div className="animatesTriangleLeft">
                <TriangleAnimated />
              </div>

              <div className="animatesTriangleRight">
                <TriangleAnimated />
              </div>
            </div>

          </div>



          <div className="home-welcome-bar" ref={this.homeWelcomeRef}>
              <div className="container">
                <h1>{this.props.t('Ticketrückerstattung')}</h1>
                <br />
                <div className="">



                  <strong>Liebe Freunde des alba Festival</strong><br /><br />

                  Ganz lieben Dank vorweg für die grosse Solidarität, die wir gegenwärtig erfahren.<br />
                  Wir fühlen uns bestärkt darin, den so kurzfristigen Entzug der Bewilligung von Frau Regierungsratspräsidentin Jacqueline Fehr (SP) nicht zu akzeptieren, zumal sie bei der Begründung des Bewilligungsentzugs massgeblich auf unsere Herkunft abstützt. Bekanntlich hatten wir ein 3G-Security-Level für das Festival vorgesehen – die Herkunft der Besucher darf dann keine Rolle spielen.<br /><br />

                  Selbstverständlich wird unser Ticketpartner See Tickets allen, die Tickets gekauft haben, zurückerstatten. Ihr alle könnt am wenigsten für die Situation, wie sie sich nun darstellt.<br /><br />

                  Und wenn ihr Fragen betreffend der Rückerstattung habt, könnt ihr selbstverständlich den See Tickets Customer Care kontaktieren, die Mailadresse lautet <a href="mailto: ticketing@seetickets.ch">ticketing@seetickets.ch</a><br /><br />

                  Wer uns unterstützen will, der kann uns gerne auf unseren Social Media Kanälen folgen und unsere News sharen.<br /><br />

                  So long und #weareone<br />
                  <strong>Euer alba Festival</strong>

                  <br /><br />
                  <hr />
                  <br /><br />


                  <strong>Dear friends of the alba Festival</strong><br /><br />

                  Thank you very much in advance for the great solidarity we are currently experiencing. <br /><br />

                  We feel strengthened in not accepting the withdrawal of the permit at such short notice by the president of the cantonal government, Jacqueline Fehr (SP), especially since she is basing the justification for the withdrawal of the permit largely on our origin. As is well known, we had planned a 3G security level for the festival - the origin of the visitors should then not play a role.<br /><br />

                  Of course, our ticket partner See Tickets will refund all those who bought tickets. All of you are least to blame for the situation as it is now.<br /><br />

                  And if you have any questions regarding the refund, you can of course contact See Tickets Customer Care, the mail address is <a href="mailto: ticketing@seetickets.ch">ticketing@seetickets.ch</a>.<br /><br />

                  If you want to support us, please follow us on our social media channels and share our news.<br /><br />

                  So long and #weareone<br />
                  <strong>Your alba Festival</strong>


                  <br /><br />
                  <hr />
                  <br /><br />

                  <strong>Chers amis du alba Festival</strong><br /><br />

                  Merci beaucoup par avance pour la grande solidarité dont nous bénéficions actuellement. <br /><br />

                  Nous nous sentons confortés dans notre refus d'accepter le retrait du permis dans un délai aussi court par la présidente du gouvernement cantonal, Jacqueline Fehr (SP), d'autant plus qu'elle fonde la justification du retrait du permis en grande partie sur notre origine. Comme vous le savez, nous avions prévu un niveau de sécurité 3G pour le festival - l'origine des visiteurs ne devrait donc pas jouer de rôle.<br /><br />

                  Bien entendu, notre partenaire See Tickets remboursera tous ceux qui ont acheté des billets conformément. Vous pouvez tous faire le moins possible pour la situation telle qu'elle est maintenant.<br /><br />

                  Et si vous avez des questions concernant le remboursement, vous pouvez bien sûr contacter le service clientèle de See Tickets, l'adresse mail est <a href="mailto: ticketing@seetickets.ch">ticketing@seetickets.ch</a>.<br /><br />

                  Si vous voulez nous soutenir, suivez-nous sur nos canaux de médias sociaux et partagez nos nouvelles.<br /><br />

                  So long et #weareone. <br />
                  <strong>Votre alba Festival</strong>


                  <br /><br />
                  <hr />
                  <br /><br />



                  <strong>Te nderuar miqe te alba Festival </strong><br /><br />

                  Si fillim, duam t’ju falenderojme perzemersisht per perkrahjen, solidaritetin qe momentalisht po e marrim nga ju. <br /><br />

                  Ndihemi te forcuar nga mohimi i lejes per festivalin tone ne ate periudhe aq afatshkurte nga Presidentja e kantonit te Zurichut, Jacqueline Fehr. Vecanerisht, kjo force vjen kur kuptojme qe vendimi i saj na ka bere te ndihemi te diskriminuar - ne origjinen tone. Sic tashme dihet, ne kishim planifikuar nje siguri te nivelit 3G per festival, gje qe ben te parendesishme origjinen e mysafireve. <br /><br />

                  Pa dyshim se partneri jone i biletave ‘See Tickets’ do ti rimbursoje te gjithe ata qe blene biletat per festival. Ju jeni ata qe keni me se paku faj per kete gjendje.<br /><br />

                  Nese keni ndonje pyetje rreth rimbursimit, mund te na kontaktoni ne qendren e kujdesit per kliente te ‘See Tickets’ ne e-mailin; <a href="mailto: ticketing@seetickets.ch">ticketing@seetickets.ch</a><br /><br />

                  Perkrahja juaj do te na beje edhe me te zëshem, prandaj ju lutem na beni follow ne rrjetet tona sociale dhe shperndani sa me shume lajmet tona. <br /><br />

                  Ne jemi një #weareone <br />
                  <strong>I juaji alba Festival</strong>

                </div>

              </div>
          </div>




        </section>

        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(HomeRefund);
